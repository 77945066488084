<template>
  <custom-vue-select
    id="status-select"
    placeholder="Please select status"
    text-field="name"
    :value-reducer="option=>option.value"
    :options="statusOptions"
    :selected.sync="statusProxy"
  />
</template>

<script>
import CustomVueSelect from './CustomVueSelect.vue'

export default {
  name: 'StatusFilter',
  components: {
    CustomVueSelect,
  },
  props: {
    status: { type: Boolean, default: null },
  },
  data() {
    return {
    }
  },
  computed: {
    statusProxy: {
      get() {
        return this.status
      },
      set(value) {
        this.$emit('update:status', value)
      },
    },
    statusOptions() {
      return [
        {
          name: 'All',
          value: null,
        },
        {
          name: 'Active',
          value: true,
        },
        {
          name: 'Inactive',
          value: false,
        },
      ]
    },
  },
}
</script>

<style lang="scss">
</style>
